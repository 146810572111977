import React, { useContext } from 'react'
import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'
import fbTrack from '~/components/fbTrack'
import HoverPopup from '../HoverPopup'

const Cart = ({ language }) => {
  const {
    store: { checkout },
  } = useContext(StoreContext)

  const handleCheckout = () => {
    fbTrack('track', 'Purchase')
    const url = `${checkout.webUrl}&locale=${language}`
    // window.open(checkout.webUrl)
    window.open(url)
  }

  const lineItems = checkout.lineItems.map(item => (
    <LineItem key={item.id.toString()} item={item} />
  ))

  const translations = {
    en: {
      termsAgreement:
        'By pressing Order you confirm you have read and agree to the ',
      terms: 'Terms and Conditions',
      termsUrl: '/terms-and-conditions/',
      order: 'Order',
      summary: 'Summary',
      products: 'Products',
      shipping: 'Shipping',
      shippingInfo:
        'Shipping is €9.99 to EU countries, and €4.95 within the Netherlands. Orders above €100 get free shipping.',
      free: '(in next step)',
      gratis: 'Free',
      total: 'Total (incl. VAT)',
    },
    nl: {
      termsAgreement: 'Door op Bestellen te drukken ga je akkoord met de ',
      terms: 'Algemene Voorwaarden',
      termsUrl: '/nl/algemene-voorwaarden',
      order: 'Bestellen',
      summary: 'Samenvatting',
      products: 'Producten',
      shipping: 'Verzending',
      shippingInfo:
        'Verzendkosten zijn €4,95 binnen Nederland en gratis bij bestellingen boven €100.',
      free: '(in volgende stap)',
      gratis: 'Gratis',
      total: 'Totaal (incl. BTW)',
    },
  }

  const lang = translations[language]

  return (
    <div className="cartContainer gridFullWidth">
      <div className="cartContentsContainer">
        {lineItems}
        <p className="termsAgreement">
          {lang.termsAgreement}
          <a href={`https://valtastudio.com/${lang.termsUrl}`} target="_blank">
            {lang.terms}
          </a>
        </p>
        <button
          className="addToCart"
          style={{ width: `100%` }}
          onClick={handleCheckout}
          disabled={checkout.lineItems.length === 0}
        >
          {lang.order}
        </button>
      </div>
      <div className="scrollingMenu cartSummary">
        <h3 style={{ marginBottom: `10px` }}>{lang.summary}</h3>
        <div className="cartSummaryPrices">
          <p>{lang.products}</p>
          <p>€ {checkout.subtotalPrice}</p>
        </div>
        <div className="cartSummaryPrices">
          <p>
            {lang.shipping}{' '}
            <span className="questionMark">
              <img src={require(`~/images/question.png`)} alt="questionmark" />
              <HoverPopup text={lang.shippingInfo} />
            </span>
          </p>
          <p style={{ color: `green`, fontWeight: 500 }}>
            {checkout.subtotalPrice > 100 ? lang.gratis : lang.free}
          </p>
        </div>
        <div className="cartSummaryPrices">
          <p>
            <strong>{lang.total}</strong>
          </p>
          <p>
            <strong>€ {checkout.totalPrice}</strong>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Cart
