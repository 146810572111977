import React, { useContext } from 'react'
import { Link } from 'gatsby'

import StoreContext from '~/context/StoreContext'

const LineItem = props => {
  const { item } = props
  const {
    removeLineItem,
    store: { client, checkout },
  } = useContext(StoreContext)

  const variantImage = item.variant.image ? (
    <img
      src={item.variant.image.src}
      alt={`${item.title} product shot`}
      height="70px"
    />
  ) : null

  const selectedOptions = item.variant.selectedOptions
    ? item.variant.selectedOptions.map(
        option => `${option.name}: ${option.value} `
      )
    : null

  const handleRemove = () => {
    removeLineItem(client, checkout.id, item.id)
  }

  return (
    <div className="lineItem">
      <Link to={`/products/${item.variant.product.handle}/`}>
        {variantImage}
      </Link>
      <div className="titleVariant">
        <h4>
          {item.title}{' '}
          {item.variant.title === !'Default Title' ? item.variant.title : ''}
        </h4>
        <p>{selectedOptions}</p>
        <p>Aantal: {item.quantity}</p>
      </div>
      <div className="priceQuantity">
        <p style={{ whiteSpace: `nowrap` }}>€ {item.variant.price}</p>
        <button onClick={handleRemove}>
          <svg width="1em" height="1em" viewBox="0 0 14 12" opacity="1">
            <g
              stroke="#000000"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="square"
              stroke-width="2"
            >
              <path d="M12.76.24L1.24 11.76M12.76 11.76L1.24.24"></path>
            </g>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default LineItem
